import React, { useState, useEffect } from 'react';
import ExploreBlogsCategories from './exploreBlogsCategories';
import ExploreBlogsCard from './exploreBlogsCard';
import cardData from '../assets/exploreBlogsCards.json'; 

export default function ExploreBlogsCardsWrapper() {
  const [activeCategory, setActiveCategory] = useState('All');
  const categories = ['All','Data Science','Business and Marketing','Development','Designing','Cyber Security','QA Automation','Finance'];

  return (
    <div className='explore-blogs-cards-wrapper'>
      <ExploreBlogsCategories
        categories={categories}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <ExploreBlogsCard category={activeCategory} cardData={cardData} />
    </div>
  );
}
