import React, { useState, useRef } from 'react';
import "../styles/BlogDetailsContent.css";
import RelatedBlog from "./RelatedBlog";

const BlogDetailsContent = () => {
    const [activeTitle, setActiveTitle] = useState(null);
    const contentRef = useRef(null);

    const blogData = [
        {
            title: "XYZ",
            description: "The old bookstore stood at the corner of Main Street, its weathered sign creaking in the wind. Inside, the shelves were lined with dusty volumes that held the secrets of centuries past. The smell of ancient paper filled the air, and every book seemed to whisper a different story, waiting for a curious reader to unlock its mysteries."
        },
        {
            title: "Content 2",
            description: "The old bookstore stood at the corner of Main Street, its weathered sign creaking in the wind. Inside, the shelves were lined with dusty volumes that held the secrets of centuries past. The smell of ancient paper filled the air, and every book seemed to whisper a different story, waiting for a curious reader to unlock its mysteries."
        },
        {
            title: "Content 3",
            description: "The old bookstore stood at the corner of Main Street, its weathered sign creaking in the wind. Inside, the shelves were lined with dusty volumes that held the secrets of centuries past. The smell of ancient paper filled the air, and every book seemed to whisper a different story, waiting for a curious reader to unlock its mysteries."
        },
        {
            title: "Content 4",
            description: "The old bookstore stood at the corner of Main Street, its weathered sign creaking in the wind. Inside, the shelves were lined with dusty volumes that held the secrets of centuries past. The smell of ancient paper filled the air, and every book seemed to whisper a different story, waiting for a curious reader to unlock its mysteries."
        },
        {
            title: "Content 5",
            description: "The old bookstore stood at the corner of Main Street, its weathered sign creaking in the wind. Inside, the shelves were lined with dusty volumes that held the secrets of centuries past. The smell of ancient paper filled the air, and every book seemed to whisper a different story, waiting for a curious reader to unlock its mysteries."
        }
    ];

    const scrollToContent = (index) => {
        if (contentRef.current && contentRef.current.children[index]) {
            contentRef.current.children[index].scrollIntoView({ behavior: 'smooth' });
            setActiveTitle(index);
        }
    }

    return (
        <div className='blog-details-container'>
            <div className='left-scroll'>
                <h1 className="orange-text">Table of Contents</h1>
                <div className='blog-content-title'>
                    <ul>
                        {blogData.map((data, index) => (
                            <li key={index} onClick={() => scrollToContent(index)} className={activeTitle === index ? 'active' : ''}>
                                {data.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='blog-content-container' ref={contentRef}>
                {blogData.map((data, index) => (
                    <div key={index} className='blog-content'>
                        <h3>{data.title}</h3>
                        <p>{data.description}</p>
                    </div>
                ))}
            </div>

            <div className='related-blogs'>
                <h2 className="orange-text">Related Blogs</h2>
                <RelatedBlog />
            </div>
        </div>
    )
}

export default BlogDetailsContent;
