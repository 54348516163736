import React, { useRef } from 'react';
import Navbar from '../CommonComponents/components/navbar';
import BlogDetailsContent from './components/BlogDetailsContent';
import BlogDetailsHero from './components/BlogDetailsHero';

export default function BlogDetails() {
    return(
        <div>
            <Navbar/>
            <BlogDetailsHero/>
            <BlogDetailsContent/>
        </div>
    )
}