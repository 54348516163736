import React from "react";
import Group2 from '../assets/Group 2.png'
import Group3 from '../assets/Group 3.png'
import backgroundHero from '../assets/image 419.png'
import OverlayHero from '../assets/image 420.png'
import latest from '../assets/noto_fire.png'
import blog_author from '../assets/Blog Author.png'
import category from '../assets/Group.png';
import '../styles/BlogDetailsHero.css'

export default function BlogDetailsHero() {
  return (
    <div className='blogdetails-hero-section'>
      <div className='blog-details-backlayer-hero'>
        
        <div className='left-blogs-hero-img-container'>
            <img src={Group2} alt='group-2'/>
        </div>
        <div className='right-blogs-hero-img-container'>
            <img src={Group3} alt='group3'/>
        </div>
      </div>
      <div className='blog-details-overlay-hero'>
        <div className='blog-details-overlay-left'>
            <div className='blog-details-hero-img-container'>
                <img src={backgroundHero} alt='overlay-hero-img'/>
            </div>
        </div>
        <div className='blog-details-overlay-right'>
                <div className="blog-category">
                    <img src={category}/>
                    <h2><span className="blog-mobile">CATEGORY: </span><span className="orange-text"> DATA SCIENCE</span></h2>
                </div>
                <div className="blog-right-pop">
                    <img src={latest}/>
                    <p>Latest</p>
                </div>
                <div className="blog-hero-content">
                    <h3>BEGINNING OF <span className="orange-text">DATA SCIENCE</span></h3>
                    <p className="blog-para1">From Data to Dollars: Maximizing Business</p>
                    <p className="blog-para2">Impact with Data Science</p>
                </div>
                <div className="blog-right-down">
                    <div>
                        04 October 2023
                    </div>
                    <div className="blog-author">
                        <h4 className="orange-text">Mark Zuckenberg</h4>
                        <img src={blog_author}/>
                    </div>
                </div>
        </div>
      </div>
    </div>
  )
}
