import React from 'react'
import '../styles/exploreProgramsHero.css'
import bg from '../assets/bg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function ExploreProgramsHero() {
  return (
    <div className='explore-programs-heroSection'>
      <div className='explore-languages-heroTop'>
      <div className='explore-programs-heroRight'>
          <img src={bg} alt='explore programs hero' />
        </div>
        <div className='explore-languages-heroLeft'>
          {/* <p className='placement-guarantee-blackText'>Placement Guarantee Courses</p> */}
          <h1>Unlock your Dream Career with<br /><span className='orange-text'>1-on-1 Teaching Friend</span></h1>
          <p className='placement-grey-text'> Unleash Your Potential, Build Interview Confidence, and Secure Your Dream Job with Our Proven Strategies and Interview Mastery</p>
          {/* <button><span className='orange-text'>Get Hired</span>  before you Graduate</button> */}
          <button>Only live "1-on-1" classes</button>
        </div>
        
      </div>
      <div className='explore-programs-heroBottom'>
        <div className='herobottom-highlights'>
          <span className='check-icon'><FontAwesomeIcon icon={faCheck} /></span>
          <p>Job-ready Portfolio</p>
        </div>
        <div className='herobottom-highlights'>
          <span className='check-icon'><FontAwesomeIcon icon={faCheck} /></span>
          <p>Mock Interviews</p>
        </div >
        <div className='herobottom-highlights'>
          <span className='check-icon'><FontAwesomeIcon icon={faCheck} /></span>
          <p>Internship Opportunites</p>
        </div>
        <div className='herobottom-highlights'>
          <span className='check-icon'><FontAwesomeIcon icon={faCheck} /></span>
          <p>Hands-on Projects</p>
        </div>
      </div>
    </div>
  )
}