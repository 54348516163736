import React from 'react'
import ExploreBlogsHero from './components/exploreBlogsHero'
import Navbar from '../CommonComponents/components/navbar'
import ExploreBlogsCardsWrapper from './components/exploreBlogsCardsWrapper'

export default function ExploreBlogs() {
  return (
    <div className='explore-blogs-page'>
        <Navbar/>
      <ExploreBlogsHero/>
      <ExploreBlogsCardsWrapper/>
    </div>
  )
}
