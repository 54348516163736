import React from 'react'
import '../styles/exploreLanguageHero.css'
import exploreProgramsHero from '../assets/ExploreProgramsHero.png'
import interaction from '../assets/1on1interaction.png'
import feedback from '../assets/intermediate_feedback.png'
import progress from '../assets/faster_progress.png'
import conf from '../assets/conf_building.png'
import bg from '../assets/cl_Hero_bg.png';

export default function ExploreLanguagesHero() {
  return (
    <div className='explore-programs-heroSection'>
      <div className='explore-languages-heroTop'>
      <div className='explore-programs-heroRight'>
          <img src={bg} alt='explore programs hero' />
        </div>
        <div className='explore-languages-heroLeft'>
          {/* <p className='placement-guarantee-blackText'>Placement Guarantee Courses</p> */}
          <h1>Unlock your Coding Skills with<br /><span className='orange-text'>1-on-1 Teaching Friend</span></h1>
          <p className='placement-grey-text'> Start your coding journey from scratch and build a <span className='orange-text'>solid foundation.</span>  <br/>We make <span className='orange-text'>coding easy</span> for you with personalized course plans, providing clear guidance and explanations.</p>
          {/* <button><span className='orange-text'>Get Hired</span>  before you Graduate</button> */}
          <button>Only live "1-on-1" classes</button>
        </div>
        
      </div>
      <div className='languages-heroBottom'>
        <div className='language-highlights'>
            <img src={interaction} alt="interaction"/>
            <h4>1 on 1 Interaction</h4>
        </div>
        <div className='language-highlights'>
            <img src={feedback} alt="interaction"/>
            <h4>Immediate Feedback</h4>
        </div>
        <div className='language-highlights'>
            <img src={progress} alt="interaction"/>
            <h4>Faster Progress</h4>
        </div>
        <div className='language-highlights'>
            <img src={conf} alt="interaction"/>
            <h4>Confidence Building</h4>
        </div>
      </div>
    </div>
  )
}