import React from 'react';
import '../styles/exploreBlogsCategories.css'

export default function ExploreBlogsCategories({ categories, activeCategory, setActiveCategory }) {
  return (
    <div className='explore-blogs-categories'>
      {categories.map((category) => (
        <div
          key={category}
          className={`category ${category === activeCategory ? 'active' : ''}`}
          onClick={() => setActiveCategory(category)}
        >
          {category}
        </div>
      ))}
    </div>
  );
}
