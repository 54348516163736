import React from 'react'
import '../styles/missionSection.css'

export default function MissionSection() {
  return (
    <div className='mission-section'>
        <h1>Our Mission</h1>
        <p>"Our goal is to provide excellent education through high-quality teaching. We keep track of your progress and provide real-world experience to enrich your learning. We help you <strong>achieve your goals</strong> with ease by customizing the course for you. We aim to help you learn essential skills without spending excessive time, ensuring your learning experience is  <strong>efficient and effective</strong>."</p>
    </div>
  )
}
