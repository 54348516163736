import React from 'react';
import FireIcon from '../assets/noto_fire.png'
import BlogAuthor from '../assets/Blog Author.png'
import '../styles/exploreBlogsCard.css'

export default function ExploreBlogsCard({ category, cardData }) {
    const cards = cardData[category] || [];

    return (
        <div className='explore-blogs-cards-container'>
            {cards.map((card) => (
                <div key={card.id} className='blog-card'>
                    <div className='blog-card-img-container'>
                        <img src={card.img} alt='card' />
                    </div>
                    <div className='blog-details'>
                        <p className='blog-date'>{card.date}</p>
                        <div className='blog-latest'>
                            <div className='fire-icon-container'>
                                <img src={FireIcon} alt='fire-icon' />
                            </div>
                            <p>Latest</p>
                        </div>
                        <p className='blog-description'>{card.description}</p>
                        <div className='blog-author-container'>
                            <p>{card.author}</p>
                            <div className='author-icon-container'>
                                <img src={BlogAuthor} alt='author-icon' />
                            </div>

                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
