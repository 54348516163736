import React , {useEffect}from 'react'
import Navbar from '../CommonComponents/components/navbar'
import Footer from '../CommonComponents/components/footer'
import Testimonials from './components/testimonials'
import CodingLanguages from './components/codingLanguages'
import ExploreLanguagesHero from './components/exploreLanguagesHero'
import DreamCareer from './components/dreamCareer'
import Services from './components/services'
import Benefits from './components/benefits'
import WhyJoinUsSection from './components/whyJoinUsSection'
import FloatingButton from '../CommonComponents/components/floatingButton';

export default function ExploreCodingLanguages() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='explore-programs-page'>
      <Navbar/>
      <ExploreLanguagesHero/>
      <CodingLanguages/>
      <WhyJoinUsSection/>
      {/* <DreamCareer/> */}
      {/* <Services/> */}
      <Testimonials/>
      {/* <Benefits/> */}
      <FloatingButton/>
      <Footer/>
    </div>
  )
}
