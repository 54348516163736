import React from "react";
import "../styles/RelatedBlog.css";
import related_blog_author from '../assets/Frame 42.png'


const RelatedBlogs = [
    {
        title: "Data Science is Growing",
        description:"The scientist peered through the microscope, marveling at the intricate world that unfolded before their eyes.",
        author:"Akhil Nisthala"
    },
    {
        title: "Data Science is Growing",
        description:"The scientist peered through the microscope, marveling at the intricate world that unfolded before their eyes.",
        author:"Akhil Nisthala"    
    },
    {
        title: "Data Science is Growing",
        description:"The scientist peered through the microscope, marveling at the intricate world that unfolded before their eyes.",
        author:"Akhil Nisthala"    
    },
    {
        title: "Data Science is Growing",
        description:"The scientist peered through the microscope, marveling at the intricate world that unfolded before their eyes.",
        author:"Akhil Nisthala"    
    },
    {
        title: "Data Science is Growing",
        description:"The scientist peered through the microscope, marveling at the intricate world that unfolded before their eyes.",
        author:"Akhil Nisthala"    
    }
]

export default function RelatedBlog() {
    return(
        <div>
            {RelatedBlogs.map((blog)=>(
                <div className="related-blog-content">
                    <h3>{blog.title}</h3>
                    <div className="related-blog-details">
                        <img src={related_blog_author}/>
                        <p>{blog.description}</p>
                    </div>
                    <p className="related-blog-author">-{blog.author}</p>
                </div>

            ))}
        </div>
    )
}