import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faStar as solidStar, faStarHalfAlt as halfStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import '../styles/codingLanguages.css';
import data from '../assets/json_data/coding_languages_info.json'
import GetInTouchPopUp from './getInTouchPopUp';


import card_img_0 from '../assets/coding_languages_images/cprogramming.png';
import card_img_1 from '../assets/coding_languages_images/c_slash.png';
import card_img_2 from '../assets/coding_languages_images/c++.png';
import card_img_3 from '../assets/coding_languages_images/java.png';
import card_img_4 from '../assets/coding_languages_images/python.png';
import card_img_5 from '../assets/coding_languages_images/javascript.png';
import card_img_6 from '../assets/coding_languages_images/typescript.png';
import card_img_7 from '../assets/coding_languages_images/php.png';
import card_img_8 from '../assets/coding_languages_images/swift.png';
import card_img_9 from '../assets/coding_languages_images/kotlin.png';
import card_img_10 from '../assets/coding_languages_images/dart.png';
import card_img_11 from '../assets/coding_languages_images/go.png';

let imgdata = {
  "0": card_img_0,
  "1": card_img_1,
  "2": card_img_2,
  "3": card_img_3,
  "4": card_img_4,
  "5": card_img_5,
  "6": card_img_6,
  "7": card_img_7,
  "8": card_img_8,
  "9": card_img_9,
  "10": card_img_10,
  "11": card_img_11
}

export default function CodingLanguages() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth);
      };
  const [programData, setProgramData] = useState([]);

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    // Remove the event listener when the component unmounts to prevent memory leaks
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  // Create separate refs for each slider
  const programCardContainerRef1 = useRef(null);
  const programCardContainerRef2 = useRef(null);
  const programCardContainerRef3 = useRef(null);

  // Create separate currentPage state variables for each slider
  const [currentPage1, setCurrentPage1] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [currentPage3, setCurrentPage3] = useState(0);

  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    setProgramData(data);
  }, []);

  // Create separate scroll handlers for each slider
  const handleScroll1 = () => {
    if (programCardContainerRef1.current) {
      const container = programCardContainerRef1.current;
      const cardWidth = container.offsetWidth;
      const scrollLeft = container.scrollLeft;
      let newPage = Math.floor((scrollLeft + cardWidth / 2) / cardWidth);

      if (newPage < 0) {
        newPage = 0;
      } else if (newPage >= programData.length) {
        newPage = programData.length - 1;
      }

      setCurrentPage1(newPage);
    }
  };

  const handleScroll2 = () => {
    if (programCardContainerRef2.current) {
      const container = programCardContainerRef2.current;
      const cardWidth = container.offsetWidth;
      const scrollLeft = container.scrollLeft;
      let newPage = Math.floor((scrollLeft + cardWidth / 2) / cardWidth);

      if (newPage < 0) {
        newPage = 0;
      } else if (newPage >= programData.length) {
        newPage = programData.length - 1;
      }

      setCurrentPage2(newPage);
    }
  };

  const handleScroll3 = () => {
    if (programCardContainerRef3.current) {
      const container = programCardContainerRef3.current;
      const cardWidth = container.offsetWidth;
      const scrollLeft = container.scrollLeft;
      let newPage = Math.floor((scrollLeft + cardWidth / 2) / cardWidth);

      if (newPage < 0) {
        newPage = 0;
      } else if (newPage >= programData.length) {
        newPage = programData.length - 1;
      }

      setCurrentPage3(newPage);
    }
  };

  useEffect(() => {
    // Add scroll event listeners to each slider
    if (programCardContainerRef1.current) {
      programCardContainerRef1.current.addEventListener('scroll', handleScroll1);
    }
    if (programCardContainerRef2.current) {
      programCardContainerRef2.current.addEventListener('scroll', handleScroll2);
    }
    if (programCardContainerRef3.current) {
      programCardContainerRef3.current.addEventListener('scroll', handleScroll3);
    }

    return () => {
      // Remove scroll event listeners when the component unmounts
      if (programCardContainerRef1.current) {
        programCardContainerRef1.current.removeEventListener('scroll', handleScroll1);
      }
      if (programCardContainerRef2.current) {
        programCardContainerRef2.current.removeEventListener('scroll', handleScroll2);
      }
      if (programCardContainerRef3.current) {
        programCardContainerRef3.current.removeEventListener('scroll', handleScroll3);
      }
    };
  }, [programData]);


  const ProgramCard = ({ title, imageSrc, period, rating, gradientColors, className, courseId, onButtonClick }) => {
    const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  console.log(onButtonClick)
  console.log(title)

    const maxRating = 5;
    const solidStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];
    for (let i = 1; i <= maxRating; i++) {
      if (i <= solidStars) {
        stars.push(
          <FontAwesomeIcon
            key={i}
            icon={solidStar}
            className='solid-star-l'
          />
        );
      } else if (hasHalfStar && i === solidStars + 1) {
        stars.push(
          <FontAwesomeIcon
            key={i}
            icon={halfStar}
            className='half-star-l'
          />
        );
      } else {
        stars.push(
          <FontAwesomeIcon
            key={i}
            icon={regularStar}
            className='regular-star-l'
          />
        );
      }
    }

    const gradientStyle = {
      background: `linear-gradient(130deg, #ffffff 15%, ${gradientColors} 46%)`
    };

    return (
      <div className={`language-card`}>
        <div className='language-image-container'>
          <img src={imgdata[courseId]} alt="Course" className="langauge-card-image" />
        </div>
        <div className='program-card-details'>
          <p className='program-card-title language-title'>{title}</p>
          <p className='language-period'><span><FontAwesomeIcon icon={faStopwatch} /></span>{period} course</p>
          <div className="rating-stars-language">
            <p>Rating : <span>{stars}</span> {rating}</p>
          </div>
          <div class="language-level">
            <p>Beginner-Intermediate</p>
          </div>
          <div className='language-buttons'>
              <button onClick={onButtonClick}>Start Now</button>
          </div>
        </div>
      </div>
    );
  };

  

  return (

    <>
    <div className='placement-programs-section'>
      <h1 className='languages-title'><span className='orange-text'>Coding </span>Languages</h1>
      <div className='placement-background-image-container'>
        <div className='placement-cards-desktop'>
          <div className='language-cards-container'>
            {programData.map((card, index) => (
              <ProgramCard
                title={card.title}
                imageSrc={card.imageSrc}
                period={card.period}
                rating={card.rating}
                courseId={card.courseId}
                gradientColors={card.gradientColors}
                onButtonClick={togglePopup}
                key={index}
                
              />
            ))}
          </div>
        </div>
        <div className='placement-cards-mobile'>
          <div className='language-cards-container'>
            {/* Slider 1 */}
            <div className='program-cards-scroll' ref={programCardContainerRef1}>
              {programData.slice(0, 4).map((card, index) => (
                <ProgramCard
                  title={card.title}
                  imageSrc={card.imageSrc}
                  period={card.period}
                  rating={card.rating}
                  courseId={card.courseId}
                  gradientColors={card.gradientColors}
                  key={index}
                  onButtonClick={togglePopup}
                  className={
                    index === currentPage1 ? '' : 'program-card-hidden'
                  }
                />
              ))}
            </div>
            <div className='pagination-dots'>
              {programData.slice(0, 4).map((_, index) => (
                <span
                  key={index}
                  className={index === currentPage1 ? 'active-dot' : 'dot'}
                  onClick={() => {
                    setCurrentPage1(index);
                    const container = programCardContainerRef1.current;
                    const cardWidth = container.offsetWidth;
                    container.scrollLeft = index * cardWidth;
                  }}
                ></span>
              ))}
            </div>

            {/* Slider 2 */}
            <div className='program-cards-scroll' ref={programCardContainerRef2}>
              {programData.slice(4, 8).map((card, index) => (
                <ProgramCard
                  title={card.title}
                  imageSrc={card.imageSrc}
                  period={card.period}
                  rating={card.rating}
                  courseId={card.courseId}
                  gradientColors={card.gradientColors}
                  key={index}
                  onButtonClick={togglePopup}
                  className={
                    index === currentPage2 ? '' : 'program-card-hidden'
                  }
                />
              ))}
            </div>
            <div className='pagination-dots'>
              {programData.slice(4, 8).map((_, index) => (
                <span
                  key={index}
                  className={index === currentPage2 ? 'active-dot' : 'dot'}
                  onClick={() => {
                    setCurrentPage2(index);
                    const container = programCardContainerRef2.current;
                    const cardWidth = container.offsetWidth;
                    container.scrollLeft = index * cardWidth;
                  }}
                ></span>
              ))}
            </div>

            {/* Slider 3 */}
            <div className='program-cards-scroll' ref={programCardContainerRef3}>
              {programData.slice(8, 12).map((card, index) => (
                <ProgramCard
                  title={card.title}
                  imageSrc={card.imageSrc}
                  period={card.period}
                  rating={card.rating}
                  courseId={card.courseId}
                  gradientColors={card.gradientColors}
                  key={index}
                  onButtonClick={togglePopup}
                  className={
                    index === currentPage3 ? '' : 'program-card-hidden'
                  }
                />
              ))}
            </div>
            <div className='pagination-dots'>
              {programData.slice(8, 12).map((_, index) => (
                <span
                  key={index}
                  className={index === currentPage3 ? 'active-dot' : 'dot'}
                  onClick={() => {
                    setCurrentPage3(index);
                    const container = programCardContainerRef3.current;
                    const cardWidth = container.offsetWidth;
                    container.scrollLeft = index * cardWidth;
                  }}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    {showPopup && <GetInTouchPopUp onClose={togglePopup} />}
    </>
  );
}