import React, { useState } from 'react'
import '../styles/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import Footerlogo from '../assets/flogo.png'

const FooterPartOne = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://v1.nocodeapi.com/chandra/google_sheets/tFxcQEOnfQbnrjgP?tabId=SubscribeForm", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([
          [
            email,
            new Date().toLocaleString()
          ]
        ])
      })
      await response.json();
      console.log("Form Submitted succesfully", response.data);
      setEmail('');
    }
    catch (error) {
      console.error("Error submitting form", error);
    }
  };




  return (
    <div className='footer-part1'>
      <div className='fpart1'>
        <p>Subscribe to our Newsletter for Latest Updates!</p>
        <form className='subscribe-form' onSubmit={handleSubmit}>
          <input
            type='email'
            placeholder='Enter your Email here'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className='subscribe-button'>Subscribe</button>
        </form>
      </div>

    </div>
  );
};


export default function Footer({ isFooterPart1 }) {

  const [namevalue, setNamevalue] = useState('');
  const [contact, setContact] = useState('');
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');

  const handleFooterSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://v1.nocodeapi.com/chandra/google_sheets/tFxcQEOnfQbnrjgP?tabId=FooterContactForm", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([
          [
            namevalue, // Get the value from the ref
            contact,
            mail,
            message,
            new Date().toLocaleString()
          ]
        ])

      });
      await response.json();
      console.log('Form submitted successfully', response.data);
      setNamevalue('');
      setContact('');
      setMail('');
      setMessage('');
    } catch (error) {
      // Handle errors, you can display an error message or perform other error handling
      console.error('Error submitting form', error);
    }
  }

  return (
    <div className='footer-section'>
      {!isFooterPart1 ? <FooterPartOne /> : ''}
      <div className='footer-part2'>
        <div className='footer-part2-main'>
          <div className='footer-part2-left'>
            <div className='footer-part2-top'>
              <img src={Footerlogo} alt='footer-logo' />
              <h1>1 on 1 Teaching Friend</h1>
            </div>

            <p>Our goal is to provide excellent education through high-quality teaching. We keep track of your progress and provide real-world experience to enrich your learning. We aim to help you learn essential skills without spending excessive time, ensuring your learning experience is efficient and effective.
              <br />
            </p>
            <div className='social-icons'>
              <div className='social-icons-top'>
                <p className='orange-text'>Follow Us: </p>
                <a href='https://www.linkedin.com/company/1-on-1-teaching-friend/about/'><FontAwesomeIcon icon={faLinkedin} /></a>
                {/* <a href=''><FontAwesomeIcon icon={faFacebook} /></a> */}
                <a href='https://instagram.com/1on1teachingfriend?igshid=MzRlODBiNWFlZA=='><FontAwesomeIcon icon={faInstagram} /></a>
                {/* <a href=''><FontAwesomeIcon icon={faTwitter} /></a> */}
              </div>
              <div className='social-icons-down'>
                <p><span><FontAwesomeIcon icon={faPhone} className='ficon' /> : +91 86882 83415</span></p>
                <p><span><FontAwesomeIcon icon={faEnvelope} className='ficon' /></span> : info@1on1teachingfriend.com</p>
              </div>

            </div>
          </div>
          <div className='footer-part2-middle'>
            <h1>Quick Links</h1>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/explorecodinglanguages">Coding</Link></li>
              <li><Link to="/exploreprograms">Streams</Link></li>
              {/* <li><Link to="/hiretalent">Hire Talent</Link></li> */}
              <li><Link to="/contactus">Contact us</Link></li>
            </ul>
          </div>
          <div className='footer-part2-right'>
            <h1>Write to us</h1>
            <form className='footer-form' onSubmit={handleFooterSubmit} >
              <div className='footer-contactus-row1'>
                <div className='fname farea'>
                  <label>Name</label>
                  <input
                    value={namevalue}
                    onChange={(e) => setNamevalue(e.target.value)}
                    required
                  />
                </div>
                <div className='fcontact farea'>
                  <label>Contact</label>
                  <input
                    type="number"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className='footer-contactus-row2'>
                <div className='fmail farea'>
                  <label>Mail</label>
                  <input
                    type="email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className='footer-contactus-row3'>
                <div className='fname farea'>
                  <label>Message</label>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </div>
              </div>
              <button>Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div className='footer-part3'>
        <p>Copyright 2023 <span className='ftitle'>1 on 1 Teaching Friend Technologies</span> Theme | All Rights Reserved</p>
      </div>
    </div>
  )
}
