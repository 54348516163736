import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/navbar.css';
import Logo from '../assets/Navbar Logo.png';
import GetInTouchPopUp from '../components/getInTouchPopUp';

// Use this import when you dropdown for explore
// import { faAngleDown, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Routes, Route, useNavigate } from 'react-router-dom';

export default function Navbar() {
  const location = useLocation();
  const [showExploreDropdown, setShowExploreDropdown] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const togglePopup = () => {
    if (mobileMenuOpen === true) {
      setMobileMenuOpen(!mobileMenuOpen);
    }
    setShowPopup(!showPopup);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleExploreDropdown = () => {
    setShowExploreDropdown(!showExploreDropdown);
  };

  return (
    <nav className={`navbar ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      <Link to='/'>
        <img src={Logo} alt='Logo' className='navbar-logo' />
      </Link>
      <div className={`navbar-buttons ${mobileMenuOpen ? 'mobile-menu' : ''}`}>
        <Link to='/' className={location.pathname === '/' ? 'active' : ''}>
          <button className='navbar-button'>Home</button>
        </Link>
        <Link to='/explorecodinglanguages' className={location.pathname === '/explorecodinglanguages' ? 'active' : ''}>
          <button className='navbar-button'>Coding</button>
        </Link>

        {/* Drop down for explore*/}
        {/* <div
          className={`navbar-button explore-drop ${location.pathname === '/exploreprograms' ? 'active' : ''}`}
          onMouseEnter={toggleExploreDropdown}
          onMouseLeave={toggleExploreDropdown}
        >
          <button className='explore-navbar-button'>Explore<FontAwesomeIcon icon={faAngleDown} className="icon angle-down" />
          </button>
          {showExploreDropdown && (
            <div className='explore-dropdown'>
              <Link to='/explorecodinglanguages' className={location.pathname === '/explorecodinglanguages' ? 'active' : ''}>Programming Languages</Link>
              <Link to='/exploreprograms' className={location.pathname === '/exploreprograms' ? 'active' : ''}>Placement Programs</Link>
            </div>
          )}
        </div> */}
        <Link to='/exploreprograms' className={location.pathname === '/exploreprograms' ? 'active' : ''}>
          <button className='navbar-button'>Streams</button>
        </Link>
        {/* <Link to='/becomeATutor' className={location.pathname === '/becomeATutor' ? 'active' : ''}>
          <button className='navbar-button'>Become a Tutor</button>
        </Link> */}
        <Link to='/contactus' className={location.pathname === '/contactus' ? 'active' : ''}>
          <button className='navbar-button'>Contact Us</button>
        </Link>
        <button className='navbar-button black-button' onClick={togglePopup}>Get in Touch</button>
      </div>
      <div className='mobile-menu-icon' onClick={toggleMobileMenu}>
        <div className={`bar ${mobileMenuOpen ? 'bar-open' : ''}`}></div>
        <div className={`bar ${mobileMenuOpen ? 'bar-open' : ''}`}></div>
        <div className={`bar ${mobileMenuOpen ? 'bar-open' : ''}`}></div>
      </div>
      {showPopup && <GetInTouchPopUp onClose={togglePopup} />}
    </nav>
  );
}