import React from 'react'
import Group2 from '../assets/Group 2.png'
import Group3 from '../assets/Group 3.png'
import OverlayHero from '../assets/image 420.png'
import '../styles/exploreBlogsHero.css'

export default function ExploreBlogsHero() {
  return (
    <div className='explore-blogs-hero-section'>
      <div className='explore-blogs-backlayer-hero'>
        
        <div className='left-blogs-hero-img-container'>
            <img src={Group2} alt='group-2'/>
        </div>
        <h1>Tech Education Stories: Learning with <span className='orange-text'>1 on 1</span></h1>
        <div className='right-blogs-hero-img-container'>
            <img src={Group3} alt='group3'/>
        </div>
      </div>
      <div className='explore-blogs-overlay-hero'>
        <div className='explore-blogs-overlay-left'>
            <div className='explore-blogs-hero-img-container'>
                <img src={OverlayHero} alt='overlay-hero-img'/>
            </div>
        </div>
        <div className='explore-blogs-overlay-right'>
            <h1><span className='orange-text'>EVERYTHING </span> IN DATA SCIENCE</h1>
            <p>"<span className='orange-text'>Data science</span> is the key to unlocking the mysteries of the universe... well, maybe not the universe, but definitely the mysteries of your business.”</p>
        </div>
      </div>
    </div>
  )
}
