import React , {useEffect}from 'react'
import Navbar from '../CommonComponents/components/navbar'
import Footer from '../CommonComponents/components/footer'
import Testimonials from './components/testimonials'
import WhyJoinUsSection from './components/whyJoinUsSection';
import PlacementPrograms from './components/placementPrograms'
import ExploreProgramsHero from './components/exploreProgramsHero'
import DreamCareer from './components/dreamCareer'
import Services from './components/services'
import Benefits from './components/benefits'
import FloatingButton from '../CommonComponents/components/floatingButton';
import ProgramDetailsCourseHighlights from './components/programDetailsCourseHighlights'

export default function ExplorePrograms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='explore-programs-page'>
      <Navbar/>
      <ExploreProgramsHero/>
      <ProgramDetailsCourseHighlights/>
      <PlacementPrograms/>
      <WhyJoinUsSection/>
      <Benefits/>
      <Services/>
      <FloatingButton/>
      <Footer/>
    </div>
  )
}
