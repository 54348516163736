import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

// import Avatarb from '../assets/Avatarb.png';
import Avatarb from '../assets/avatar_9.jpg'
import avatarb1 from '../assets/avatarb1.jpg';
import avatarb2 from '../assets/avatarb2.jpg';
import avatarb3 from '../assets/avatarb3.jpg';
import avatarg1 from '../assets/avatarg_1.png'
import avatarg2 from '../assets/avatarg_2.jpg'
import avatarg3 from '../assets/avatarg_3.jpg'
import avatarg4 from '../assets/avatarg_4.jpg'
import avatarg5 from '../assets/avatarg_5.jpg'

import '../styles/testimonials.css';

const TestimonialCardData = [
  {
    photo: avatarg1,
    title: 'Shreya Shrivastava',
    subtitle: 'Student',
    description: 'Being a fresher, I started learning Python on my own as a beginner and later joined 1-on-1 to explore advanced concepts. Joining 1-on-1 was a game-changer. I not only explored advanced concepts but also excelled in my learning.',
  },
  {
    photo: Avatarb,
    title: 'Mokshith Reddy',
    subtitle: 'Student',
    description: ' Highly recommended for students aiming to build a strong coding foundation. Starting from the very beginning, the instruction here is perfect for those who wish to lay a strong foundation in coding.'
  },
  {
    photo: avatarg2,
    title: 'Maneesha B',
    subtitle: 'Student',
    description: 'The teaching was good, and I valued every minute of it. The clarity and effective doubt clearance were standout features, and I appreciate the encouragement provided. Thanks to 1-on-1 Teaching Friend platform.',
  },
  {
    photo: avatarg5,
    title: 'Preethi Sai',
    subtitle: 'Student',
    description: 'The mentor was very patient, addressing every doubt with patience. Daily tasks improved my coding skills in various topics. Thanks to his guidance, I have built a strong foundation in the coding language.',
  },
  {
    photo: avatarb2,
    title: 'Santhosh K',
    subtitle: 'Student',
    description: 'Students with no prior language experience can start learning coding on this platform at affordable prices. I am grateful to my friend for recommending the 1-on-1 Teaching Friend platform. ',
  },
  
  {
    photo: avatarg3,
    title: 'Pravalika S',
    subtitle: 'Student',
    description: 'I have always wanted to start coding from scratch. Fortunately, I came across the 1-on-1 Teaching Friend platform. It has been a great experience - the personalized guidance, and clear explanations have made learning journey smooth.'
  },
  {
    photo: avatarg4,
    title: 'Vaishnavi Reddy',
    subtitle: 'Student',
    description: 'Initially, I thought there would be pre-recorded videos, followed by 1-on-1 doubt sessions. However, I was pleasantly surprised—it is actually live 1-on-1 teaching. I highly recommend the 1-on-1 Teaching friend platform to my friends.',
  },
  {
    photo: avatarb3,
    title: 'Yeshwanth Kumar',
    subtitle: 'Student',
    description: 'The right path for beginners to kickstart their careers in Java programming. The course breaks down each topic clearly. Daily tasks related to the topics not only simplify understanding but also open doors for us to explore more and learn.'
  },
  {
    photo: avatarb1,
    title: 'Vineeth Reddy',
    subtitle: 'Student',
    description: 'I have a strong interest in mastering the Python language, but initially I struggled to find a platform that aligned with my preferences. Eventually, I discovered the 1-on-1 teaching platform, and I enthusiastically began my learning journey.'
  },
];

const TestimonialCard = ({ photo, title, subtitle, description, isActive }) => {
  return (
    <div className={`testimonial-card`}>
      <img src={photo} alt={title} className='testimonial-photo' />
      <h3 className='testimonial-title'>{title}</h3>
      <h4 className='testimonial-subtitle'>{subtitle}</h4>
      <FontAwesomeIcon icon={faQuoteLeft} className='testimonial-quoteIcon' />
      <div className='testimonial-description'>{description}</div>
    </div>
  );
};

export default function Testimonials() {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % TestimonialCardData.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='testimonials-section'>
      <div className='testimonials-nav'>
        <p>
          What Our <span className='testimonial-orange-text'>Students</span> Say About Us
        </p>
      </div>
      <div className='testimonials-card-container'>
        <div className='testimonials-cards'>
          {TestimonialCardData.map((card, index) => (
            <TestimonialCard
              key={index}
              photo={card.photo}
              title={card.title}
              subtitle={card.subtitle}
              description={card.description}
            />
          ))}
        </div>
        <div className='testimonials-cards-mobile'>
          {TestimonialCardData.map((card, index) => (
            <TestimonialCard
              key={index}
              photo={card.photo}
              title={card.title}
              subtitle={card.subtitle}
              description={card.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}